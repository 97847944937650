.sd-root-modern {
    --sd-base-padding: 5px;
    /* --sd-mobile-width: 1920px; */
}

.sd-root-modern.sd-root-modern--mobile {
    --sd-base-padding: 5px;
}

.sd-body {
    padding: 0px;
}

.sd-body .sd-body__page {
    padding: 0px;
}

.sd-element--nested.sd-panel {
    margin-top: 0px;
    padding-left: 10px;
    padding-right:10px;
    /* border: 1px green solid; */
}

.sd-element--with-frame {
    padding-left: 0px;
    padding-right: 0px;
}

.sd-panel{
    padding: 0px;
    /* border: 1px rgb(218, 85, 255) solid; */
    border-radius: 0px;
}

.sd-progress-buttons__list li {
    padding-bottom: 0px;
}
.sd-row {
    padding:0px;
    width: 100%;
    /* border: 1px red solid; */
}

.sd-row--multiple{
    gap: 0px;
}

.sd-image__image {
    border-radius: 0px;
}

.sd-action-bar {
    display: flex;
    align-items: center;
    justify-content: center;

}

.sd-input {
    min-width: 100px;
}

.sd-root-modern--mobile .sd-btn {
    width: 180px;
    height: 60px;
    background-color: #19b394;
    color: white;
    font-size: 20px;
}

div:only-child>.sd-element--with-frame:not(.sd-element--collapsed) {
    padding-left:0px;
    padding-right:0px;
}

.sd-progress-buttons__list .sd-progress-buttons__list-element--passed {
    border-top: 6px solid #19b394;
}

.sv_qstn .sq-root {
    border: 1px solid gray;
    border-left: 4px solid #18a689;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
}

.sv_qstn .sq-root-cb {
    border-left: 4px solid orange;
}

.sq-title {
    font-size: 20px;
    margin-left: 20px;
}

.sq-title-required {
    color: red;
}

.sq-label {
    margin-left: 30px;
}

.sq-item:nth-child(1) {
    margin-bottom: 5px;
}