$primary: var(--primary, #19b394);
$primary-foreground: var(--primary-foreground, #fff);
$primary-light: var(--primary-light, rgba(25, 179, 148, 0.1));

$background: var(--background, #fff);
$background-dim: var(--background-dim, #f3f3f3);
$foreground: var(--foreground, #161616);
//todo: discuss this variable
$foreground-disabled: var(--foreground-disabled, rgba(#161616, 0.16));

$base-unit: var(--base-unit, 8px);
@function calcSize($multiplier) {
  $result: calc(#{$multiplier} * #{$base-unit});
  @return $result;
}

sv-popup {
  display: block;
  position: absolute;
  z-index: -1;
}
.sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  outline: none;
}

.sv-popup__container {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.1));
  position: absolute;
  padding: 0;
}
.sv-popup__shadow {
  width: 100%;
  height: 100%;
}

.sv-popup__body-content {
  background-color: $background;
  border-radius: calcSize(0.5);

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  max-width: 90vw;
}

.sv-popup--modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .sv-popup__container {
    position: static;
  }

  .sv-popup__body-content {
    padding: calcSize(4);
  }
}

.sv-popup--overlay {
  width: 100%;

  .sv-popup__container {
    background: rgba(144, 144, 144, 0.5);
    max-width: 100vw;
    max-height: calc(100vh - 1 * #{$base-unit});
    height: calc(100vh - 1 * #{$base-unit});
    width: 100%;
    padding-top: calcSize(2);
    border: unset;
  }

  .sv-popup__body-content {
    max-height: 100vh;
    max-width: 100vw;
    border-radius: calcSize(2) calcSize(2) 0px 0px;
    background: $background;
    box-shadow: 0px calcSize(1) calcSize(2) rgba(0, 0, 0, 0.1);
    padding: calcSize(3) calcSize(2) calcSize(2);
    height: calc(100% - calc(1 * #{$base-unit}));
  }

  .sv-popup__scrolling-content {
    height: calc(100% - (10 * var(--base-unit, 8px)));
  }

  .sv-popup__body-footer {
    margin-top: calcSize(2);
  }

  .sv-popup__body-footer-item {
    width: 100%;
  }

  .sv-popup__button--cancel {
    background-color: $primary;
    border: 2px solid $primary;
    color: $primary-foreground;
  }
}

.sv-popup__scrolling-content {
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: $background-dim;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-light;
  }
}

.sv-popup__content {
  min-width: 100%;
}

.sv-popup--show-pointer.sv-popup--top {
  .sv-popup__pointer {
    transform: translate(calcSize(-1)) rotate(180deg);
  }
}
.sv-popup--show-pointer.sv-popup--bottom {
  .sv-popup__pointer {
    transform: translate(calcSize(-1), calcSize(-1));
  }
}
.sv-popup--show-pointer.sv-popup--right {
  transform: translate(calcSize(1));
  .sv-popup__pointer {
    transform: translate(-12px, -4px) rotate(-90deg);
  }
}
.sv-popup--show-pointer.sv-popup--left {
  transform: translate(calcSize(-1));
  .sv-popup__pointer {
    transform: translate(-4px, -4px) rotate(90deg);
  }
}

.sv-popup__pointer {
  display: block;
  position: absolute;
  &:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-left: calcSize(1) solid transparent;
    border-right: calcSize(1) solid transparent;
    border-bottom: calcSize(1) solid $background;
    align-self: center;
  }
}

.sv-popup__body-header {
  font-family: Open Sans;
  font-size: calcSize(3);
  line-height: calcSize(4);
  font-style: normal;
  font-weight: 700;
  margin-bottom: calcSize(2);
  color: $foreground;
}

.sv-popup__body-footer {
  display: flex;
  margin-top: calcSize(4);
}
.sv-popup__body-footer-item:first-child {
  margin-left: auto;
}
.sv-popup__body-footer-item + .sv-popup__body-footer-item {
  margin-left: calcSize(1);
}
.sv-popup__button {
  padding: calcSize(2) calcSize(6);
  background: $background;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;

  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);
  text-align: center;
  color: $primary;

  border: none;
  &:hover {
    box-shadow: 0 0 0 2px $primary;
  }
  outline: none;
}
.sv-popup__button:disabled {
  color: $foreground-disabled;
  cursor: default;
  &:hover {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  }
}
.sv-popup__button--apply {
  background-color: $primary;
  color: $primary-foreground;
}
.sv-popup__button--apply:disabled {
  background-color: $background-dim;
}
